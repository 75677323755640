// Import the functions you need from the SDKs you need 
import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAdkQ6uqz-zoFMedh9tof0G1J1QoepPkkk",
  authDomain: "randomeats-32bfd.firebaseapp.com",
  projectId: "randomeats-32bfd",
  storageBucket: "randomeats-32bfd.firebasestorage.app",
  messagingSenderId: "939957206968",
  appId: "1:939957206968:web:ab36090ad7e0347e58994d",
  measurementId: "G-WNBM8CVJLX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;
