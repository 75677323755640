import React, { useState } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import app from './firebaseConfig'; // import Firebase initialization

const functions = getFunctions(app);


function OrderSearch() {
  const [place, setPlace] = useState('');
  const [order, setOrder] = useState('');
  const [showCard, setShowCard] = useState(false); // State for card visibility
  const [mustHave, setMustHave] = useState('');
  const [noHave, setNoHave] = useState('');


  const handleSearch = async () => {
    if (!place) return; // Ensure a city is entered

    try {
      const getOrderSuggestion = httpsCallable(functions, 'getOrderSuggestion');
      const response = await getOrderSuggestion({ place, mustHave, noHave });

      setOrder(response.data.order);
      setShowCard(true); // Show the card when a suggestion is made
    } catch (error) {
      console.error("Error fetching suggestion:", error);
      setOrder("Sorry, we couldn't fetch a suggestion. Please try again.");
    }
  };

   // Function to close the card
   const handleCloseCard = () => {
    setShowCard(false);
    setPlace('');
    setOrder(''); // Optionally reset the order suggestion
    setNoHave('');
    setMustHave('');
  };


  return (
    <div className="container">
      {/*<button className="AdFreeButton">{'Go Ad Free'}</button>*/}
      <h1 className="title">Random Order</h1>
      <div className="search-container">
        <input
          type="text"
          className="input"
          placeholder="Enter any chain/local place"
          value={place}
          onChange={(e) => setPlace(e.target.value)}
        />
        <button className="enterButton" onClick={handleSearch}>{'>'}</button>
      
      
      </div>

      <div className="form-group">
        <label className="optional-label">Optional</label>
        <input
          type="text"
          className="input"
          placeholder="No's (ex. Olives, Onions)"
          value={noHave}
          onChange={(e) => setNoHave(e.target.value)}
        />
      </div>

      <div className="form-group">
        <label className="optional-label">Optional</label>
        <input
          type="text"
          className="input"
          placeholder="Must's (ex. Ham, Mayo)"
          value={mustHave}
          onChange={(e) => setMustHave(e.target.value)} 
        />
      </div>


      {showCard &&(
        <div className="card">
          <button className="close-button" onClick={handleCloseCard}>X</button>
          <p><b>At {place}, We Suggest:</b><br/><br/>{order}</p>
        </div>
      )}

        
    
    
    </div>
  );
}

export default OrderSearch;
