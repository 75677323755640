import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import CitySearch from './CitySearch';
import OrderSearch from './OrderSearch';
import randomEatzLogo from './randomEatzLogo2.svg'


function Home() {

  return (
    <div className="container">
      {/*<button className="AdFreeButton">{'Go Ad Free'}</button>*/}

      <div className="header-image">
        <img src={randomEatzLogo} alt="RandomEatz" className="Title-Img" />
      </div>
      
      {/* Link to navigate to the city search page */}
      <Link to="/city-search">
        <button className="button">Random Place</button>
      </Link>
      <Link to="/order-search">
        <button className="button">Random Order</button>
      </Link>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/city-search" element={<CitySearch />} />
        <Route path="/order-search" element={<OrderSearch />} />
      </Routes>
    </Router>
  );
}

export default App;
